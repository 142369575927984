<script lang="ts">
    import "leaflet/dist/leaflet.css"
    import LeafletMap from "@svelte-leaflet-main/components/LeafletMap.svelte"
    import TileLayer from "@svelte-leaflet-main/components/TileLayer.svelte"
    import {DragEndEvent, LatLng, LatLngTuple, Map} from "leaflet"
    import {GeoSearchControl, OpenStreetMapProvider} from "leaflet-geosearch"
    import 'leaflet-geosearch/dist/geosearch.css'
    import {AnimatePresence, Motion} from "svelte-motion"
    import {Easing} from "@utils/Easing"
    import {createEventDispatcher, onMount} from "svelte"
    import Marker from "@svelte-leaflet-main/components/Marker.svelte"
    import tooltip from "@utils/Action/Tooltip"
    import FormTypeErrors from "@shared/Form/FormTypeErrors.svelte"
    import {FormEntry} from "@shared/Form/interface"
    import HiddenType from "@shared/Form/HiddenType/HiddenType.svelte"
    import Translator from "bazinga-translator";

    export let entry: FormEntry

    const dispatch = createEventDispatcher()

    const mapOptions = {
        zoom: 11,
        center: [48.974697477419, 14.47438830901033],
    }

    export let markerLatLng: LatLngTuple | LatLng | undefined = mapOptions.center

    let mapEl: {
        getMap: () => Map
    }


    const tileLayerOptions = {
        minZoom: 0,
        maxZoom: 20,
        maxNativeZoom: 19,
    }

    const tileUrl = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

    const provider = new OpenStreetMapProvider({
        params: {
            email: "admin@mujstavebnidenik.cz", // TODO: change email to MSD one
            "accept-language": "cs",
            countrycodes: "cz",
            addressdetails: 1,
        },
    })

    let bigView = false

    onMount(() => {
        if (mapEl?.getMap()) {

            mapEl.getMap().addControl(new GeoSearchControl({
                provider,
                position: "topleft",
                style: "bar",
                searchLabel: "Např. České Budějovice 37001",
                showMarker: false,
            }))

            mapEl.getMap().on("geosearch/showlocation", e => {
                if(entry.disabled) {
                    return
                }

                // @ts-ignore
                markerLatLng = [e.location.y, e.location.x]
                // @ts-ignore
                entry.value = e.location.lat + "," + e.location.lng
                dispatch("geoSelect", e)
            })
        }
    })

    $: {
        if (markerLatLng && mapEl && mapEl?.getMap) {
            mapEl?.getMap()?.flyTo(markerLatLng, 14, { animate: false })
        }
    }

    const handlePinDragEnd = (e: CustomEvent<DragEndEvent>): void => {
        if(entry.disabled) {
            return
        }

        const latLng: LatLng = e.detail.target.getLatLng()
        markerLatLng = latLng

        entry.value = latLng.lat + "," + latLng.lng

        provider.search({
            query: latLng.lat + "," + latLng.lng
        }).then(result => {
            if (result.length > 0) {
                dispatch("geoSelect", {
                    location: result[0],
                })
            }
        })
    }

</script>

<div class="flex relative justify-center items-center w-96 h-48 z-50">
    <Motion let:motion
            animate={{
                width: bigView ? "60rem" : "24rem",
                height: bigView ? "30rem" : "12rem",
                y: bigView ? "9rem" : "0rem"
            }}
            onUpdate={() => mapEl?.getMap().invalidateSize()}
            transition={{
                type: "timing",
                duration: .5,
                ease: Easing.easeOutQuint,
            }}
    >
        <div use:motion class="w-96 h-48 rounded-md overflow-hidden absolute {entry.disabled ? 'shadow-md msd-hide-geo-search' : ''} {bigView ? 'shadow-xl' : 'shadow-md msd-hide-geo-search'}">
            <LeafletMap bind:this={mapEl} options={mapOptions}>
                <TileLayer options={tileLayerOptions} url={tileUrl}/>

                {#if markerLatLng !== undefined}
                    {#key bigView}
                        <Marker options={{
                                    draggable: !entry.disabled && bigView
                                }}
                                latLng={markerLatLng}
                                events={["dragend"]}
                                on:dragend={handlePinDragEnd}
                        />
                    {/key}
                {/if}
            </LeafletMap>

            {#if !entry.disabled}
                <AnimatePresence show={bigView}>
                    <Motion let:motion
                            initial={{
                                opacity: 0
                            }}
                            animate={{
                                opacity: 1
                            }}
                            exit={{
                                opacity: 0
                            }}
                            transition={{
                                type: "timing",
                                duration: .5,
                                ease: Easing.easeOutQuint,
                            }}
                    >
                        <div use:motion class="absolute bottom-0 flex w-full select-none z-[400]">
                            <div class="text-black w-full flex justify-center mt-auto">
                                <small class="font-semibold italic py-1 bg-white rounded-t-md text-center px-3">
                                    {Translator.trans("form.gps.help1")}
                                    <br>
                                    {Translator.trans("form.gps.help2")}
                                </small>
                            </div>
                        </div>
                    </Motion>
                </AnimatePresence>
            {/if}
            <button class="absolute right-3 top-3 bg-white rounded-md shadow-md z-[400]"
                    use:tooltip={{placement: "left", content: bigView ? Translator.trans("shrink") : entry.disabled ? Translator.trans("enlarge") : Translator.trans("select_on_map")}}
                    on:click|preventDefault={() => bigView = !bigView}
            >
                {#if bigView}
                    <img src="/image/delete.svg" alt="map pick" class="w-8 h-8 p-2 object-contain"/>
                {:else}
                    <img src="/image/search.svg" alt="map pick" class="w-8 h-8 p-2 object-contain"/>
                {/if}
            </button>
        </div>
    </Motion>
    <HiddenType entry={entry}/>

    <FormTypeErrors errors={entry.errors}/>
</div>

<style lang="scss">
    :global(.leaflet-control-geosearch input) {
        background-color: white;
    }

    :global(.msd-hide-geo-search .leaflet-control-geosearch ) {
        display: none;
    }
</style>
